import { ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider, QueryClient } from "react-query";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { store } from "./store/index";
import { loginSuccess, setRegion } from "./slices/authSlice";
import {
	loginSuccess as userLoginSuccess,
	setRegion as setUserRegion,
} from "./slices/userAuthSlice";
import { REGION_KEY, ROLEKEY, USERKEY } from "./utils/constants";
import auth from "./utils/helpers/auth";
import customTheme from "./config/themeConfig";
import BaseRoute from "./pages/routes/BaseRoutes";
import { ModalProvider } from "./hooks/ModalContext";
import DownloadAppModal from "./components/Home/DownloadAppModal";
import SupportSocketProvider from "./context/chatSocket";
import "react-toastify/dist/ReactToastify.css";
import { payDecryption } from "./utils/helpers/functions";

if (auth.authenticate()) {
	const accessCode = auth.getAccessCode();
	const region = localStorage.getItem(REGION_KEY);
	const parsedRegion = JSON.parse(region);

	store.dispatch(
		loginSuccess({
			accessCode,
		})
	);
	if (region) {
		store.dispatch(setRegion(parsedRegion));
	}
}

if (auth.userAuthenticate()) {
	const user = localStorage.getItem(USERKEY);
	const parsedUser = JSON.parse(user);
	const region = localStorage.getItem(REGION_KEY);
	const parsedRegion = JSON.parse(region);
	store.dispatch(
		userLoginSuccess({
			...parsedUser,
			role: localStorage.getItem(ROLEKEY),
		})
	);
	if (region) {
		store.dispatch(setUserRegion(parsedRegion));
	}
}

console.log({
	descr: payDecryption(
		"KFt/BBC1NkwD34rMWYr0UOe7JOROscypFh1GE1Qoz8T0J08lNqBP0XPrMonWd6X+2+L1NNgCpvX5gv0WF4cOcSwM25PvOGzLjKPcEPTwegtuo7XuDv3dFQ1S6chlE6tBDNcB5E8mQ/NIcFzf/8PQXxzPUANj4QoIC4UxVkGN0hwKcmR4ehFBLnUaVZDkUGDpDOz2dAjVKdecTJBfeXVRIsXF/YY6jG6bQUzSH3DxNVMmiuzUmzNinsf11UiPSu/OkpF991WI56U5ZyUYOHZclBg2K4Il9/4MDyaXkBB3pKKpjQDEOIHoCbCXo+j+XBgeb43HlK56B/XhAgGonORQ/I9RvzQtNFu4jgBLZDQGb+mUw9BUuEM6zS7RAlrxfqHJYjVvBfOVzQTrAt5LlqpHZoN8reTk1dAluQvLxtvIXr5hqkLI+xqmyi92x1rXA7MHzOdU97z1akawY9vR6MP4DFE98ZInbpyHWFZ9yt7ydehLjCLZHsceq9bA5ckP2kzl6d31ttkfg5kNZvY1e3uwmmXAOJMfJSqUqlfajOJvQ7sNEtKMrXdUl4dQgMgPzyo6dB8G8nYM8qsLajZk0EwAJ9u6ne81K0gXnO0DWnrcn7ljZu/6BGJhNs6ti9T57ZJFxzCBxpj5vDvqXwDQ/8LOZyUKPGQFcdAV6igP9VvuttcG8+27e3rsn6cKDIyKXjdhB4dU2UNpH4cFsZw23is33q6183vP1+GGn19LW0/ikRnzHqmvhWKkR3b6Hvg5mtBAnUkMGCsncHYD1VxEbn8HaQ=="
	),
});

function App() {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				retry: false,
				cacheTime: 0,
			},
		},
	});
	return (
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<SupportSocketProvider>
					<ThemeProvider theme={customTheme}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<ModalProvider>
								<CssBaseline />
								<ToastContainer />
								<BaseRoute />

								<DownloadAppModal />
							</ModalProvider>
						</LocalizationProvider>
					</ThemeProvider>
				</SupportSocketProvider>
			</QueryClientProvider>
		</Provider>
	);
}

export default App;
